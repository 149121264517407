/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTwitter } from "@fortawesome/free-brands-svg-icons/faTwitter"
import { faFacebookF } from "@fortawesome/free-brands-svg-icons/faFacebookF"
import { faInstagram } from "@fortawesome/free-brands-svg-icons/faInstagram"
import { faMap } from "@fortawesome/free-solid-svg-icons/faMap"
import { faAngleRight } from "@fortawesome/free-solid-svg-icons/faAngleRight"
import { faPhone } from "@fortawesome/free-solid-svg-icons/faPhone"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope"
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons/faWhatsapp"
import { OverlayTrigger } from "react-bootstrap"
import { Popover } from "react-bootstrap"

import Header from "./header"
import footerLogo from "../../static/images/del-navbar.png"
import "../../static/styles/main.scss"
import { library } from "@fortawesome/fontawesome-svg-core"
import RefForwardingLink from "./ref-forwarding-link"

library.add(faTwitter)
library.add(faFacebookF)
library.add(faInstagram)
library.add(faAngleRight)
library.add(faWhatsapp)
library.add(faMap, faEnvelope, faPhone)

const phonePopover = (
  <Popover id="popover-basic" className={"p-3 bg-grey z-max"}>
    <Popover.Content className={"w-100 bg-grey"}>
      <a
        target="_blank"
        href="tel:+919029023000"
        className={"btn btn-primary btn-block mb-3"}
      >
        Mumbai
      </a>
      <a
        target="_blank"
        href="tel:+9000680094"
        className={"btn btn-primary btn-block mb-3"}
      >
        Bengaluru
      </a>
    </Popover.Content>
  </Popover>
)

const whatsappPopover = (
  <Popover id="popover-basic" className={"p-3 bg-grey z-max"}>
    <Popover.Content className={"w-100 bg-grey"}>
      <a
        target="_blank"
        href="https://wa.me/919029023000"
        className={"btn btn-primary btn-block mb-3"}
      >
        Mumbai
      </a>
      <a
        target="_blank"
        href="https://wa.me/919000680094"
        className={"btn btn-primary btn-block mb-3"}
      >
        Bengaluru
      </a>
    </Popover.Content>
  </Popover>
)

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => (
      <>
        <style
          dangerouslySetInnerHTML={{
            __html: `
        .bottom-bar svg {
            width: 0.875em;
        }

        .think-cake {
          width: 0.875em;
        }

        .fa-2x {
          font-size: 2em;
        }
      `,
          }}
        />
        <Header siteTitle={data.site.siteMetadata.title} />
        <main>{children}</main>
        <footer>
          <div className={"container"}>
            <img src={footerLogo} alt={"Deliciae Cakes"} />
            <p className={"copyright"}>© 2019 Deliciae. All rights reserved.</p>
            <div className={"social-icons"}>
              <a
                href="https://www.facebook.com/delcakes.in"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={["fab", "facebook-f"]} />
              </a>
              <a
                href="https://www.instagram.com/delcakes.in"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={["fab", "instagram"]} />
              </a>
            </div>
            <div classname={"legal"} style={"font-size: 12px;"}>
              <a href="/terms">Terms and Conditions</a>
              &nbsp;|&nbsp;
              <a href="/privacy">Privacy Policy</a>
            </div>
          </div>
        </footer>
        <div className={"fixed-bottom d-flex d-md-none"}>
          <a
            href="/"
            rel="noreferrer noopener"
            className={"bottom-bar whatsapp"}
          >
            <img src="/images/d-only.svg" className={"d-logo"} alt="Home" />
          </a>
          <a
            className={"bottom-bar instagram"}
            href={"https://www.instagram.com/delcakes.in"}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon
              icon={["fab", "instagram"]}
              size="2x"
              className={"mr-2"}
            />
          </a>
          <OverlayTrigger
            rootClose={true}
            trigger={"click"}
            key="top"
            overlay={phonePopover}
          >
            <RefForwardingLink className={"bottom-bar phone"}>
              <FontAwesomeIcon
                icon={["fas", "phone"]}
                size="2x"
                transform="rotate-90"
              />
            </RefForwardingLink>
          </OverlayTrigger>
          <OverlayTrigger
            rootClose={true}
            trigger={"click"}
            key="top"
            overlay={whatsappPopover}
          >
            <RefForwardingLink className={"bottom-bar whatsapp"}>
              <FontAwesomeIcon icon={["fab", "whatsapp"]} size="2x" />
            </RefForwardingLink>
          </OverlayTrigger>
        </div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
